<template>
    <div class="p-5 rounded-lg shadow relative min-h-[400px] bg-white dark:bg-gray-750">
        <template v-if="loading">
            <div class="absolute top-0 left-0 w-full h-full z-40 backdrop-blur-sm flex items-center justify-center">
                <Loading />
            </div>
        </template>

        <div>
            <h2 class="font-semibold text-2xl dark:text-gray-300">{{component.display_name}}</h2>
        </div>

        <template v-if="data && data.data">
            <div class="mt-5 rounded-lg">
                <dl class="divide-y divide-gray-200 px-4">

                    <template v-for="item in data.data" :key="item.id">
                        <div class="py-3 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt class="text-base font-semibold text-gray-900 dark:text-gray-200 flex items-center">
                                {{item.stockable.model}}  <span class="pl-2 font-medium text-sm text-gray-500"> ({{item.production_count}})</span>
                            </dt>

                            <dd class="mt-1 flex items-center text-base text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                                <span class="flex-grow font-semibold">
                                    <span class="text-red-400">{{item.quantity}}</span> / {{item.min_level}} gab.
                                </span>

                                <span class="text-base flex-shrink-0 font-semibold text-green-500 mr-2">
                                    {{item.warehouse.name}}
                                </span>

                                <button class="text-blue-400 hover:bg-blue-100 dark:hover:bg-blue-200 dark:hover:text-blue-700 px-2 py-2 rounded-full" @click="openOrderApplication(item)">
                                + Ražot
                                </button>
                            </dd>
                        </div>
                    </template>
                </dl>
            </div>

            <template v-if="this.data && this.data.meta && this.data.meta.current_page">
                <div class="flex items-center justify-end sm:px-6 pt-3">
                    <div class="flex-1 flex items-center justify-end">
                        <div>
                            <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                <button @click="changPage(-1)" :disabled="this.data.meta.current_page == 1" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-750 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-40">
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </button>

                                <button @click="changPage(1)" :disabled="this.data.meta.current_page == this.data.meta.last_page" type="button" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-750 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-40">
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import axios from "axios"
var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

import { defineAsyncComponent } from 'vue'

const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))

export default {
    name: "LowStockWidget",
    components: {
        Loading,
    },
    props: {
        component: {
            type: Object
        }
    },
    data() {
        return {
            data: null,
            page: 1,
            loading: false,
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            axios
            .get(baseUrl + "/api/dashboard_components/" + this.component.id + "?page=" + this.page)
            .then((response) => {
                this.data = response.data
                this.loading = false
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.$store.dispatch("destroyToken");
                }
                this.loading = false
            });
        },
        changPage(number) {
            this.page = this.page + number

            if (this.page < 1) {
                this.page = 1
            }
            if (this.page > this.data.meta.last_page) {
                this.page = this.data.meta.last_page
            }
            this.getData()
        },
        openOrderApplication(item){

            let routeData = this.$router.resolve(
                {
                path: '/production/production_applications/create/',
                query: {
                    name: item.stockable.name,
                    id: item.stockable.id,
                    quantity: item.is_for_production,
                }
                });
            window.open(routeData.href, '_blank');
        },
    }
}
</script>

<style>

</style>